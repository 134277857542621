import React, { useState, useEffect } from "react";

import moment from 'moment';
import _ from 'lodash';
// react plugin for creating charts
import Chart from 'react-google-charts';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Icon, Divider, Select, MenuItem } from "@material-ui/core";
// @material-ui/icons
import Accessibility from "@material-ui/icons/Accessibility";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Container from "@material-ui/core/Container";

import clicksIcon from "assets/icon/cursor-click-icon.svg";
import linksIcon from "assets/icon/links-icon.svg";
import singleIcon from "assets/icon/single-neutral-icon.svg";

import {
  dailySalesChart,
  platformPieChart,
  geoChart
} from "variables/charts.js";

import linksService from 'services/links.js';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);



const dateFromObjectId = function(objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export default function Dashboard(props) {
  const classes = useStyles();
  const [now, setNow] = useState(+moment());

  const [totalClicks, setTotalClicks] = useState(0);
  const [dailyClicks, setDailyClicks] = useState(0);

  const [timeRangeIndex, setTimeRangeIndex] = useState(0);
  const [timeRange, setTimeRange] = useState([+moment(now).subtract(1, 'day'), +moment(now).add(1, 'day').startOf('day')]);
  const [timeRangeValues, setTimeRangeValues] = useState([
    [+moment(now).startOf('day'), +moment(now).endOf('day')]
  ]);
  const [uniqueVisitors, setUniqueVisitors] = useState(0);
  const [totalActiveLinks, setTotalActiveLinks] = useState(0);
  const [topLinks, setTopLinks] = useState([
    []
  ]);
  const [platformData, setPlatformData] = useState([
    ['OS', 'Clicks']
  ]);
  const [browserData, setBrowserData] = useState([
    ['Browser', 'Clicks']
  ]);
  const [referrerData, setReferrerData] = useState([
    ['Referrer', 'Clicks']
  ]);
  const [timeClicks, setTimeClicks] = useState([]);
  const [graphClickData, setGraphClickData] = useState([
    ['Day', 'Total'],
    [0, 0]
  ]);
  const [geoClickData, setGeoClickData] = useState([
    ['Country', 'Clicks'],
    ['United States', 0]
  ]);






  const updateGraphClickData = (getClicks, numTimeUnits = 7, timeUnit = 'day') => {
    setGraphClickData([
      [timeUnit, 'Current', 'Previous'],
      ..._.range(~~numTimeUnits, -1).map(timeUnitsAgo => [`${moment(timeRange[1]).subtract(timeUnitsAgo, timeUnit).format(timeUnit === 'day' ? 'dd M/D' : 'hA M/D')} vs ${moment(timeRange[0]).subtract(timeUnitsAgo, timeUnit).format(timeUnit === 'day' ? 'dd M/D' : 'hA M/D')} `,
        getClicks(timeUnitsAgo, timeUnit),
        getClicks(timeUnitsAgo, timeUnit, true)
      ])
    ]);
  };

  const handleTimeRangeSelectChange = event => {
    setTimeRangeIndex(event.target.value);
    setTimeRange(timeRangeValues[event.target.value]);
    const [fromTime, toTime] = timeRangeValues[event.target.value];
    const extractTimeClicks = (daysAgo, timePeriod = 'day', beforeStart = false) =>
      timeClicks.filter(e => moment(e.timestamp).isSame(moment(timeRangeValues[event.target.value][beforeStart ? 0 : 1]).subtract(daysAgo, timePeriod), timePeriod)).length;
    const selectedDuration = moment.duration(moment(toTime).diff(moment(fromTime)));
    
    if (selectedDuration.asDays() > 1) {
      updateGraphClickData(extractTimeClicks, selectedDuration.asDays());
    } else if (selectedDuration.asHours() >= 1) {
      updateGraphClickData(extractTimeClicks, selectedDuration.asHours(), 'hour');
    }
  };

  useEffect(() => {
    async function fetchData() {
      const myLinks = await linksService.getMyLinksDetails();
      const allClicks = myLinks.flatMap(link => link.clickData);
      const tempTimeClicks = allClicks.map(e => ({ ...e, timestamp: dateFromObjectId(e._id) }));
      setTimeClicks(tempTimeClicks);
      const extractClicks = (daysAgo, timePeriod = 'day') => tempTimeClicks.filter(e => moment(e.timestamp).isSame(moment(now).subtract(daysAgo, timePeriod), timePeriod)).length
      
      
      
      setTotalClicks(myLinks.reduce((a, e) => a + e.clicks, 0));
      setTotalActiveLinks(myLinks.length);
      setTopLinks(myLinks
        .sort((a, b) => b.clicks - a.clicks)
        .map((link, i) => [String(i + 1), link.deepLink, link.originalLink, String(link.clicks)])
      );
      /* following should be refactored with ramda or lodash or underscore or something dear god please */
      setDailyClicks(extractClicks(0));
      const platformEntries = Object.entries(_.countBy(_.filter(allClicks, 'platform'), 'platform'));
      setPlatformData([
        ['Operating System', 'Clicks'],
        ...platformEntries
      ]);
      const browserEntries = Object.entries(_.countBy(_.filter(allClicks, 'browser'), 'browser'));
      setBrowserData([
        ['Browser', 'Clicks'],
        ...browserEntries
      ]);
      const referrerEntries = Object.entries(_.countBy(_.filter(allClicks, 'referrer'), 'referrer'));
      setReferrerData([
        ['Referrer', 'Clicks'],
        ...referrerEntries
      ]);
      
      
      const geoEntries = Object.entries(_.countBy(allClicks, 'country')).filter(e => e[0] !== 'undefined');
      
      setGeoClickData([
        ['Country', 'Clicks'],
        ...geoEntries
      ]);
      setUniqueVisitors(Object.keys(_.countBy(allClicks, 'ip')).length)
      setTimeRangeValues([
        [+moment(now).startOf('day'), +moment(now).add(1, 'day').startOf('day')],
        [+moment(now).subtract(1, 'day').startOf('day'), +moment(now).startOf('day')],
        [+moment(now).subtract(7, 'day'), +moment(now).endOf('day')],
      ]);
      handleTimeRangeSelectChange({ target: { value: 0 } });
      const tempTimeRange = [+moment(now).startOf('day'), +moment(now).add(1, 'day').startOf('day')];
      setGraphClickData([
        ['hour', 'Current', 'Previous'],
        ..._.range(~~24, -1).map(timeUnitsAgo => [`${moment(tempTimeRange[0]).subtract(timeUnitsAgo, 'hour').format('hA M/D')} vs ${moment(tempTimeRange[1]).subtract(timeUnitsAgo, 'hour').format('hA M/D')} `,
          tempTimeClicks.filter(e => moment(e.timestamp).isSame(moment(tempTimeRange[1]).subtract(timeUnitsAgo, 'hour'), 'hour')).length,
          tempTimeClicks.filter(e => moment(e.timestamp).isSame(moment(tempTimeRange[0]).subtract(timeUnitsAgo, 'hour'), 'hour')).length,
        ])
      ]);
    }
    fetchData();
  }, []);

  return (
    <div className={classes.cusCards}>
      <Container className={classes.container}>
      <GridContainer>
          <GridItem md={12}>
        <h1>Dashboard</h1>
        </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} id="testingtest">
              <Card className="smOnMobile">
                <CardHeader>
                  <div className="inlineCard">
                  <div className="img-outr">
                    <img src={clicksIcon} alt="logo"/>
                  </div>
                    <p className={classes.cardCategory}>{totalClicks} Clicks</p>
                  </div>
                </CardHeader>
              </Card>
              <Card className="smOnMobile">
                <CardHeader>
                <div className="inlineCard">
                    <div className="img-outr">
                      <img src={singleIcon} alt="logo"/>
                    </div>
                    <p className={classes.cardCategory}>{uniqueVisitors} Visitors</p>
                  </div>
                </CardHeader>
              </Card>
              <Card className="smOnMobile">
                <CardHeader>
                <div className="inlineCard">
                <div className="img-outr">
                  <img src={linksIcon} alt="logo"/>
                  </div>
                  <p className={classes.cardCategory}>{totalActiveLinks} Active Links</p>
                  </div>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <Card chart>
                  <CardHeader>
                  <h4 className={classes.cardTitle}>Daily Clicks</h4>
                  </CardHeader>
                  <CardBody>
                  <Chart
                      className="ct-chart"
                      chartType="LineChart"
                      data={graphClickData}
                      options={dailySalesChart.options}
                    />
                  </CardBody>
                  <CardFooter>
                    <div className="footrwrap">
                      <Select value={timeRangeIndex} onChange={handleTimeRangeSelectChange}>
                        <MenuItem value={0}>Today</MenuItem>
                        <MenuItem value={1}>Yesterday</MenuItem>
                        <MenuItem value={2}>Last 7 Days</MenuItem>
                      </Select>
                      <p className={classes.cardCategory}>
                      {dailyClicks} today
                      </p>
                    </div>
                  </CardFooter>
                </Card>
            </GridItem>
        </GridContainer>
        
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <Card chart>
              <CardHeader>
              <h4 className={classes.cardTitle}>Platform Distribution</h4>
                <p className={classes.cardCategory}>
                </p>
              </CardHeader>
              <CardBody>
                <Chart
                  data={platformData}
                  chartType="PieChart"
                  options={platformPieChart.options}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card chart>
              <CardHeader>
              <h4 className={classes.cardTitle}>Browser Distribution</h4>
                <p className={classes.cardCategory}>
                </p>
              </CardHeader>
              <CardBody>
                <Chart
                  data={browserData}
                  chartType="PieChart"
                  options={platformPieChart.options}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card chart>
              <CardHeader>
              <h4 className={classes.cardTitle}>Referrer Distribution</h4>
                <p className={classes.cardCategory}>
                </p>
              </CardHeader>
              <CardBody>
                <Chart
                  data={referrerData}
                  chartType="PieChart"
                  options={platformPieChart.options}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card chart>
              <CardHeader>
              <h4 className={classes.cardTitle}>Location Data</h4>
              </CardHeader>
              <CardBody>
              <Chart
                  chartType="GeoChart"
                  data={geoClickData}
                  options={geoChart.options}
                  mapsApiKey="AIzaSyC0AVOZ8gQ7d2meHvuDBtl7gcE03xv0e-Q"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" className="no-margin">
                <h4 className={classes.cardTitleWhite}>Top Links</h4>
                <p className={classes.cardCategoryWhite}>
                  Updated as of {(new Date(Date.now()).toLocaleString())}
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["Rank", "Deep Link", "Original Link", "Clicks"]}
                  tableData={topLinks.slice(0,5)}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        
      </Container>
    </div>
  );
}