import axios from "axios";
import { audience } from "../auth_config.json";
import { getTokenSilently } from "../react-auth0-spa.js";

const baseUrl = "/api/links";

const getMyLinks = async () => {
  const accessToken = await getTokenSilently({ audience });
  const res = await axios.get(baseUrl, {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    }
  });
  return res.data;
};

const getMyLink = async (linkId) => {
  const accessToken = await getTokenSilently({ audience });
  const res = await axios.get("/api/" + linkId, {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    }
  });
  return res.data;
};
const getAuthHeader = async () => {
  const accessToken = await getTokenSilently({ audience });
  return { "Authorization": `Bearer ${accessToken}` };
};
const getMyLinksDetails = async () => {
  const accessToken = await getTokenSilently({ audience });
  const res = await axios.get(`${baseUrl}/details`, {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    }
  });
  return res.data;
};

const getLinkDetails = async (id) => {
  const accessToken = await getTokenSilently({ audience });
  const res = await axios.get(`${baseUrl}/${id}/details`, {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    }
  });
  return res.data;
};

//old code
// const create = async (newObject) => {
//   const accessToken = await getTokenSilently({ audience })
//   const res = await axios.post(baseUrl, newObject, {
//     headers: {
//       'Authorization': `Bearer ${accessToken}`
//     }
//   })
//   return res.data;
// }

const remove = async (id) => {
  const accessToken = await getTokenSilently({ audience });
  const res = await axios.delete(`${baseUrl}/${id}`, {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    }
  });
  return res.data;
};


const create = async (newObject) => {
  const authHeader = await getAuthHeader();
  return window
    .fetch(baseUrl, {
      method: "POST",
      headers: {
        ...authHeader,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newObject)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 201) {
        return res.json();
        console.log("201");
      } else if (res.status === 400) {
        return res.json();
        console.log("400");
      } else {
        // const check_deeplink_error={error: 'Something went wrong'};
        // return res.json(check_deeplink_error);
        return null;
      }
    });
};

const update1 = (id, newObject) => axios.put(`${baseUrl}/${id}`, newObject).then(res => res.data);

// by tech leadz
// const update = async (newObject) => {
//   const accessToken = await getTokenSilently({ audience })
//   const res = await axios.put(baseUrl, newObject, {
//     headers: {
//       'Authorization': `Bearer ${accessToken}`
//     }
//   })
//   return res.data;
// }

const update = async (newObject) => {
  const authHeader = await getAuthHeader();
  return window
    .fetch(baseUrl, {
      method: "PUT",
      headers: {
        ...authHeader,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newObject)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 201) {
        return res.json();
        console.log("201");
      } else if (res.status === 400) {
        return res.json();
        console.log("400");
      } else {
        return null;
      }
    });
};

export default {
  getMyLinks,
  getMyLinksDetails,
  getLinkDetails,
  create,
  remove,
  update
};