import React from "react";
import CreatorDialog from 'components/CreatorDialog/CreatorDialog.js';
const AddLink = props => {
	return (
		<div>
			<CreatorDialog />
		</div>
	);
};

export default AddLink;