// @material-ui/icons
import {
  Create,
  Dashboard,
  List,
  Person,
  Settings as SettingsIcon
} from '@material-ui/icons';
// core components/views for Admin layout
import Lander from 'views/Lander/Lander.js';
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import Accounts from "views/Accounts/Accounts.js";
import Settings from "views/Settings/Settings.js";
import MyLinks from "views/MyLinks/MyLinks.js";
import YourLinks from "views/YourLinks/YourLinks.js";
import AddLink from "views/MyLinks/AddLink.js";
import EditLink from "views/MyLinks/EditLink.js";

const dashboardRoutes = [{
  path: '/creator',
  name: 'Lander',
  icon: Create,
  component: Lander,
  layout: '',
  primary: true
},
  // {
  //   path: "/links",
  //   name: "My Links",
  //   icon: List,
  //   component: MyLinks,
  //   layout: "",
  //   private: true,
  // },
  {
    path: "/links",
    name: "Your Links",
    icon: List,
    component: YourLinks,
    layout: "",
    private: true,
  },
  {
    path: "/create-link",
    name: "Add Link",
    icon: List,
    component: AddLink,
    layout: "",
    private: true,
  },
  {
    path: "/edit-link/:id",
    name: "Edit Link",
    icon: List,
    component: EditLink,
    layout: "",
    private: true,
  },
  {
    path: "/dashboard",
    name: "Analytics",
    icon: Dashboard,
    component: DashboardPage,
    layout: "",
    private: true,
  },
  {
    spacer: true
  },
  {
    path: "/user",
    name: "Profile",
    icon: Person,
    component: UserProfile,
    layout: "",
    private: true,
  },
  {
    path: "/accounts",
    name: "Accounts",
    icon: List,
    component: Accounts,
    layout: "",
    private: true,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: Settings,
    layout: "",
  },
  {
    path: "/:id",
    name: "Edit Link",
    icon: SettingsIcon,
    component: EditLink,
    layout: "",
  },
];

export default dashboardRoutes;