// import {
//   primaryColor,
//   whiteColor,
// } from "assets/jss/material-dashboard-react.js";
const headerStyle = theme => ({
  appbar: {
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    border: "0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    zIndex: 99,
    position: 'relative',
  },
  logo: {
    "& img": {
      verticalAlign: "top",
    },
  },
  grow: {
    display: 'inline-flex',
    flexGrow: 1,
    paddingLeft: 64,
    "& a": {
      minHeight: 70,
      borderBottom: '4px solid transparent',
      borderRadius: 0,
      display: 'inline-flex',
      margin: '0 16px 0 0',
      textDecoration: 'none',
      color: '#fff',
      verticalAlign: 'top',
      "&.active": {
        borderBottomColor: '#EAAD50',
      },
      "& img": {
        marginRight: 12,
      },
      "& span": {
        fontSize: 16,
        textTransform: 'initial',
      },
      "& button": {
        padding: '0 10px',
      },
      '@media screen and (max-width: 640px)': {
        minHeight: 0,
        borderBottom: 'none',
        borderLeft: '2px solid transparent',
        '&:before': {
          display: 'inline-block',
          content: 'test',
       },
        '&.active': {
          borderLeftColor: '#EAAD50',
        },
        '& button': {
          width: '100%',
          justifyContent: 'flex-start',
          margin: '4px 0',
          padding: '10px 12px',
        }
      },
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
    },
  },
  menuButton: {
    marginRight: '10px',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255,255,255,0.25)',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.25)',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    "& button": {
      marginRight: 20,
      minHeight: 70,
      borderBottom: '4px solid transparent',
      borderRadius: 0,
      "& img": {
        marginRight: 12,
      },
      "& span": {
        fontSize: 16,
        textTransform: 'initial',
      }
    },
    '@media screen and (min-width: 641px)': {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'inline-flex',
    marginLeft: 'auto',
    '@media screen and (min-width: 641px)': {
      display: 'none',
    },
  },

});

export default headerStyle;
