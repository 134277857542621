import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import stripeApi from "services/payments.js";
import {useAuth0} from 'react-auth0-spa.js';

import Button from "components/CustomButtons/Button.js";
// import classes from "*.module.css";



export default function CheckoutForm(props) {
  const { loading, user } = useAuth0();

  // const [amount, setAmount] = useState(0);
  // const [currency, setCurrency] = useState("");
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { planIndex } = props;

  useEffect(() => {
    stripeApi.getProductDetails(planIndex).then(productDetails => {
      // setAmount(productDetails.unit_amount / 100);
      // setCurrency(productDetails.currency);
    });

  }, [planIndex]);
  
  useEffect(() => {
    
  }, [loading]);

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value,
          email: user.email
        }
    });

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
      
    } else {
      setError(null);
      setProcessing(false);      

      await stripeApi.updatePaymentMethod({paymentMethod: payload.paymentMethod})
      
      // await stripeApi.subscribe({customerId: customer.id, planIndex});
    }
  };

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
    };

    return (
      <form onSubmit={handleSubmit} className="cusForm">
        {/* <h1>
          {currency.toLocaleUpperCase()}{" "}
          {amount.toLocaleString(navigator.language, {
            minimumFractionDigits: 2
          })}{" "}
        </h1> */}
        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Cardholder Name"
              autoComplete="cardholder"
              className="sr-input"
            />
          </div>

          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
            />
          </div>
        </div>

        {error && <div className="message sr-field-error">{error}</div>}

        <Button variant="contained" disabled={processing || !stripe}>
          {processing ? "Processing…" : "Update Billing Information"}
        </Button>
      </form>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {renderForm()}
      </div>
    </div>
  );
}