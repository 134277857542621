import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  title: {
    fontWeight: 700,
    lineHeight: '1.125',
    fontSize: '1.8rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2rem'
    }
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  tiers: {
    paddingBottom: theme.spacing(18),
  },
}));

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      'Up to 1,500 clicks per month',
      'Up to 10 deeplinks',
      'Basic analytics',
      'Email support'
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Basic',
    subheader: 'Most popular',
    price: '9.99',
    description: [
      'Up to 10,000 clicks per month',
      'Unlimited deeplinks',
      'Basic analytics',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Premium',
    price: '24.99',
    description: [
      'Up to 25,000 clicks per month',
      'Unlimited deeplinks',
      'Basic analytics',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
  },
  {
    title: 'Enterprise',
    price: 'Contact us',
    description: [
      'Unlimited clicks per month',
      'Unlimited deeplinks',
      'Advanced analytics',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="sm" component="main" className={classes.heroContent} id='pricing'>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom className={classes.title}>
          Plans
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          Choose whatever plan best fits your needs!
        </Typography>
      </Container>
      <Container maxWidth="xl" component="main" className={classes.tiers}>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 4} lg={3}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center', color: 'primary' }}
                  subheaderTypographyProps={{ align: 'center', color: 'primary' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="primary">
                      {tier.title === 'Enterprise' ? '' : '$'}{tier.price}
                    </Typography>
                    <Typography variant="h6" color="primary">
                      {tier.title==='Enterprise' ? '': '/mo'}
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line} color='primary'>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}