import {
  transition,
} from "assets/jss/material-dashboard-react.js";
// import color from "@material-ui/core/colors/amber";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    // backgroundColor: '#F5F3F9',
    [theme.breakpoints.up("md")]: {
      width: '100%',
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    // marginTop: "<24></24>px",
    padding: "74px 0",
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 0 0',
    },
    minHeight: "calc(100vh - 123px)"
  },
  map: {
    marginTop: "70px"
  },
  container: {
    // marginTop: "70px",
    maxwidth: '1110px',
    width: '100%',
    marginTop: 0, 
    marginBottom: 0,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
        marginTop: 0,
    },
    '& h1': {
      letterSpacing: 0,
      color: '#202020',
      fontSize: 34,
      fontWeight: 'bold',
      margin: '0 0 20px 0',
      '@media screen and (max-width: 599px)': {
        margin: '0 0 14px 0',
        fontSize: 26,
      },
    },
  },
});

export default appStyle;
