import { primaryColor, successColor } from './material-dashboard-react.js';

const headerFonts = ['"Montserrat"', '"Roboto"', 'sans-serif'].join(',');

const overrides = {
  palette: {
    primary: {
      main: primaryColor[0]
    },
    secondary: {
    	main: successColor[0]
    }
  },
  typography: {
  	h1: {
  		fontFamily: headerFonts,
  	},
  	h2: {
  		fontFamily: headerFonts,
  	},
  	h3: {
  		fontFamily: headerFonts,
  	},
  	h4: {
  		fontFamily: headerFonts,
  	},
  }
};

export {overrides as default};