import { audience } from '../auth_config.json';
import { getTokenSilently } from '../react-auth0-spa.js';

const getAuthHeader = async () => {
  const accessToken = await getTokenSilently({ audience });
  return { 'Authorization': `Bearer ${accessToken}` };
}

const createPaymentIntent = async (options) => {
  const authHeader = await getAuthHeader();
  return window
    .fetch(`/payments/create-payment-intent`, {
      method: "POST",
      headers: {
        ...authHeader,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        
        throw new Error("PaymentIntent API Error");
      } else {
        return data.client_secret;
      }
    });
};

const getCustomer = async (options) => {
  const authHeader = await getAuthHeader();
  return window
    .fetch(`/payments/create-customer`, {
      method: "POST",
      headers: {
        ...authHeader,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        
        throw new Error("create-customer API Error");
      } else {
        return data;
      }
    });
}

const updatePaymentMethod = async (options) => {
  const authHeader = await getAuthHeader();
  return window
    .fetch(`/payments/updatePaymentMethod`, {
      method: "POST",
      headers: {
        ...authHeader,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        
        throw new Error("updatePaymentMethod API Error");
      } else {
        return data;
      }
    });
}

const subscribe = async (options) => {
  const authHeader = await getAuthHeader();
  return window
    .fetch(`/payments/subscribe`, {
      method: "POST",
      headers: {
        ...authHeader,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        
        throw new Error("subscribe API Error");
      } else {
        return data;
      }
    });
}

const getProductDetails = async (planIndex) => {
  const authHeader = await getAuthHeader();
  return window
    .fetch(`/payments/product-details/${planIndex}`, {
      method: "GET",
      headers: {
        ...authHeader,
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const getPublicStripeKey = async (options) => {
  return window
    .fetch(`/payments/public-key`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        
        throw Error("API Error");
      } else {
        return data.publicKey;
      }
    });
};

const api = {
  createPaymentIntent,
  getCustomer,
  subscribe,
  updatePaymentMethod,
  getPublicStripeKey,
  getProductDetails
};

export default api;