import React, {useState, useEffect} from "react";
import { Link, useHistory, NavLink} from "react-router-dom";
import classNames from "classnames";
import {makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from "@material-ui/core/Divider";
import Dialog from '@material-ui/core/Dialog';
import CreatorDialog from 'components/CreatorDialog/CreatorDialog.js';
import { useAuth0 } from "../../react-auth0-spa";

import MenuIcon from '@material-ui/icons/Menu';
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import logo from "assets/img/new-logo.png";
import accountIcon from "assets/icon/people-man-icon.svg";
import dashboardIcon from "assets/icon/dashboard-icon.svg";
import newLinkIcon from "assets/icon/link-icon.svg";
import listIcon from "assets/icon/list-icon.svg";



const useStyles = makeStyles(styles);
export default function DashboardHedr() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const history = useHistory();
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const [creatorOpen, setCreatorOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleLogout = () => {
    logout();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setIsExpanded(!isExpanded);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
                        { isAuthenticated ?
                          <div>
                        <MenuItem
                          onClick={e=>{
                            handleCloseProfile();
                            history.push('/accounts');
                          }}
                          className={classes.dropdownItem}
                        >
                          Profile
                        </MenuItem>
                        
                        <MenuItem className={classes.dropdownItem} onClick={e => {
                          handleCloseProfile();
                          history.push('/settings');
                        }}>
                          Settings
                        </MenuItem>
                        <Divider light />
                        <MenuItem
                          onClick={handleLogout}
                          className={classes.dropdownItem}
                        >
                          Logout
                        </MenuItem>
                        </div>
                        :
                        <MenuItem
                          onClick={loginWithRedirect}
                          className={classes.dropdownItem}
                        >
                          Login
                        </MenuItem>
                        }
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
      <NavLink activeClassName="active" to="/dashboard">
          <img src={dashboardIcon} alt="logo"/>
          <span>Dashboard</span>
      </NavLink>    
      </MenuItem>
      <MenuItem>
      <NavLink activeClassName="active" to="/create-link">
        <Button aria-label="show 11 new notifications" color="inherit">
        <img src={newLinkIcon} alt="logo"/>
        <span>Create a link</span>
        </Button>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <Button
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <img src={accountIcon} alt="logo"/>
        </Button>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );


  return (
    <div>
    <AppBar position="static" className={classes.appbar}>
      <Toolbar className={isExpanded == true ? "expanded" : ""}>
        <div edge="start" className={classes.logo} > 
            <NavLink activeClassName="active" to='/'>
            <img src={logo} alt="logo" className={classNames(classes.logoImage)}/>
            </NavLink>
          </div>
        {/* <div  className={classes.grow} /> */}
        <div className={classes.grow}>
          <NavLink activeClassName="active" to="/dashboard" onClick={e => setIsExpanded(false) }>
          <Button aria-label="show 4 new mails" color="inherit">
            <img src={dashboardIcon} alt="logo"/>
            <span>Dashboard</span>
          </Button>
          </NavLink>
          <NavLink activeClassName="active" to="/create-link" onClick={e => setIsExpanded(false) }>
            <Button aria-label="show 17 new notifications" color="inherit">
              <img src={newLinkIcon} alt="logo"/>
              <span>Create Link</span>
            </Button>
          </NavLink>
          <NavLink activeClassName="active" to="/links" onClick={e => setIsExpanded(false) }>
          <Button aria-label="show 17 new notifications" color="inherit">
            <img src={listIcon} alt="logo"/>
            <span>My Links</span>
          </Button>
          </NavLink>
          <NavLink activeClassName="active" to="/accounts" className="onlyMobile" onClick={e => setIsExpanded(false) }>
          <Button aria-label="show 17 new notifications" color="inherit">
          <img src={accountIcon} alt="logo"/>
            <span>Account</span>
          </Button>
          </NavLink>
        </div>
        <div edge="end" className={classes.sectionDesktop}>
          <Button
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <img src={accountIcon} alt="logo"/>
            <span>Account</span>
          </Button>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            onClick={handleMobileMenuOpen}
            color="inherit"
            className="buttonMobile"
          >
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
    {renderMobileMenu}
    {renderMenu}
  </div>
  );
}