import React from "react";
import EditDialog from 'components/CreatorDialog/EditDialog.js';
const EditLink = props => {
	return (
		<div>
			<EditDialog {...props} />
		</div>
	);
};

export default EditLink;